<template>
  <div>
    <div v-if="setConfirmationModal">
      <ConfirmationYear :setConfirmationModal="setConfirmationModal" :YearOfCode="YearOfCode" :verifyCode="verifyCode"
        :closeConfirmationModal="closeConfirmationModal" />
    </div>
    <div class="px-4 sm:px-6 lg:px-4 xl:px-6 pt-4 pb-4 sm:pb-6 lg:pb-4 xl:pb-6 space-y-4 h-full">

      <div :class="dark ? 'border-gray-700 bg-gray-700 text-white' : ''" class="border rounded-lg py-3 h-full">
        <div :class="isLoading ? 'hidden' : 'block'">
          <div class="style-yasser">
            <div class="sm:block hidden">
              <div class="w-full flex justify-center">
                <el-steps :active="active" finish-status="success" class="mt-10 w-2/3 " align-center>
                  <el-step title="Information sur l'abonnement"></el-step>
                  <el-step title="Insertion du code de l'abonnement"></el-step>
                  <el-step title="Félicitation"></el-step>
                </el-steps>
              </div>
            </div>
            <div class="sm:hidden block w-full flex justify-center">
              <el-steps :active="active" finish-status="success" class="mt-6 w-full " align-center>
                <el-step description="Information sur l'abonnement"></el-step>
                <el-step description="Insertion du code de l'abonnement"></el-step>
                <el-step description="Félicitation"></el-step>
              </el-steps>
            </div>

            <div v-if="active == 0" class="w-full mt-10">
              <div class="w -full flex justify-center">
                <span class="sm:text-6xl text-4xl">{{ yearInfo.title }}</span>
              </div>
              <div class="w-full flex justify-center mt-4">
                <span v-if="yearInfo.old_price != null" class="sm:text-3xl text-2xl line-through mx-4 text-gray-400">{{
                  yearInfo.old_price }}</span>
                <span class="sm:text-4xl text-2xl  mx-4">{{ yearInfo.price }}</span>
              </div>
              <table style="width:100%" class="sm:block hidden mt-4">
                <tr>
                  <td class="font-bold text-lg p-3 align-top">Description: </td>
                  <td class="py-3 px-6"><span v-html="yearInfo.description"></span></td>
                </tr>
              </table>
              <table style="width:90%" class="sm:hidden mx-4 block mt-4">
                <tr class="font-bold text-xl p-3 text-left"> Description: </tr>
                <tr class="py-3 px-6"><span v-html="yearInfo.description"></span></tr>
              </table>
            </div>

            <div v-if="active == 1" class="w-full mt-10 mb-4">
              <div class="w-full flex justify-center">
                <span class="sm:mx-10 mx-6">L'abonnement sera activé après la insertion du code du paiment qui est
                  composé de 16 chiffres et lettres dans le champ ci-dessus.
                  Concernant les méthodes de paiment, vous pouvez acheter des carte dans nos points de vente, ou bien
                  nous envoyer une preuve de paiment (photo) d'un paiment par CCP ou par l'application BaridiMob dans
                  notre compte facebook.
                </span>
              </div>

              <div class="w-full flex justify-center mt-8">
                <div class="w-5/6 flex justify-center items-center">
                  <button @click="account = 'cpp'"
                    :class="account === 'cpp' ? 'bg-red-400 text-white border-2 border-red-400' : 'bg-white border-r-0 border-2 border-red-400 text-red-400'"
                    class="py-2 px-9 flex items-center font-normal text-sm rounded-l-lg hover:shadow-lg transition duration-500 transform-gpu hover:scale-110 ">Compte
                    CCP</button>
                  <button @click="account = 'baridi'"
                    :class="account === 'baridi' ? 'bg-red-400 text-white border-2 border-red-400' : 'bg-white border-2 border-red-400 text-red-400'"
                    class="py-2 px-5 flex items-center  font-normal text-sm  rounded-r-lg hover:shadow-lg transition duration-500 transform-gpu hover:scale-110 ">Compte
                    BaridiMob</button>
                </div>
              </div>

              <div class="w-full my-4 flex justify-center">
                <table :class="account === 'cpp' ? 'block' : 'hidden'">
                  <tr>
                    <td class="font-bold sm:text-lg text-m p-1 align-top">Num du compte: </td>
                    <td class="p-1 sm:text-lg text-m">Bientôt disponible.</td>
                  </tr>
                  <tr>
                    <td class="font-bold sm:text-lg text-m p-1 align-top">Nom du propriétaire: </td>
                    <td class="p-1 sm:text-lg text-m">Mystidia</td>
                  </tr>
                  <tr>
                    <td class="font-bold sm:text-lg text-m p-1 align-top">Montant: </td>
                    <td class="p-1 sm:text-lg text-m">{{ yearInfo.price }}</td>
                  </tr>
                </table>

                <table :class="account === 'baridi' ? 'block' : 'hidden'">
                  <tr>
                    <td class="font-bold sm:text-lg text-m p-2 align-top">code RIP: </td>
                    <td class="p-2 sm:text-lg text-m">Bientôt disponible.</td>
                  </tr>
                  <tr>
                    <td class="font-bold sm:text-lg text-m p-2 align-top">Montant: </td>
                    <td class="p-2 sm:text-lg text-m">{{ yearInfo.price }}</td>
                  </tr>
                </table>
              </div>

              <div class="w-full flex justify-center mb-10">
                <div class="sm:w-1/3 w-5/6">
                  <el-input @input="change" v-model="code" placeholder="XXXX-XXXX-XXXX-XXXX" clearable maxlength="19"
                    show-word-limit>
                  </el-input>
                </div>
              </div>



            </div>

            <div v-if="active == 2" class="w-full flex justify-center">
              <img class="h-96 sm:w-auto w-5/6 rounded-xl m-14" src="../../assets/felicitation.png" />
            </div>

            <div v-if="active != 2" class="flex mx-3 justify-between items-center mt-4">
              <button :class="active == 1 ? '' : 'opacity-0 pointer-events-none'" @click="previous"
                class="py-2 px-4 flex items-center text-white font-normal text-sm bg-red-400 rounded-lg hover:shadow-lg transition duration-500 transform-gpu hover:scale-110 ">
                <span>Retour
                </span>
              </button>
              <button @click="next"
                class="py-2 px-4  flex items-center text-white font-normal text-sm bg-red-400 rounded-lg hover:shadow-lg transition duration-500 transform-gpu hover:scale-110 ">
                <span v-if="active == 0">Suivant</span>
                <span v-if="active == 1">Valider
                </span>
              </button>
              <div
                class="pointer-events-none opacity-0 py-2 px-4 flex items-center text-white font-normal text-sm bg-red-400 rounded-lg hover:shadow-lg transition duration-500 transform-gpu hover:scale-110 ">
                Retour
              </div>
            </div>
          </div>



        </div>
        <div :class="isLoading ? 'block' : 'hidden'" class="flex items-center justify-center h-full w-full">
          <!--<div class="fulfilling-square-spinner">
        <div class="spinner-inner"></div>
      </div>-->
          <div class="loading">
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axiosIns from '../../plugins/axios';
import { ElMessage } from 'element-plus';
import { h } from 'vue'
import ConfirmationYear from './SubscriptionModals/ConfirmationYearModal.vue'
export default {
  components: {
    ConfirmationYear
  },

  data() {
    return {
      isLoading: false,
      yearInfo: [],
      active: 0,
      visible: false,
      account: null,
      code: null,
      setConfirmationModal: false,
      YearOfCode: null,
      subscriptionsList: []
    }
  },

  props: {
    dark: Boolean,
    // linkPath:Function,
  },

  methods: {
    closeConfirmationModal() {
      this.setConfirmationModal = false
    },
    change() {
      this.code = this.spacify(this.code, 4, "-");
      this.code = this.code.toLowerCase();
    },
    spacify(str, after, c) {
      after = after || 4;
      c = c || " ";
      var v = str.replace(/[^\dA-Za-z]/g, ''),
        reg = new RegExp(".{" + after + "}", "g");
      return v.replace(reg, function (a) {
        return a + c;
      }).replace(/[^0-9A-Za-z]+$/, "");
    }
    ,
    next() {

      if (this.active < 3) {
        if (this.active == 0) {
          this.active = this.active + 1;
        } else if (this.active == 1) {
          axiosIns.get("/getyearsubscribe/" + this.code + "/" + this.$route.query.year).then(({ data }) => {

            if (data.result == 0) {
              if ((data.year[0] === null || typeof data.year[0] === 'undefined') && (data.module === null || typeof data.module === 'undefined')) {
                this.verifyCode()
              } else if ((data.year[0] !== null || typeof data.year[0] !== 'undefined') && (data.module === null || typeof data.module === 'undefined')) {
                this.setConfirmationModal = true
                if (data.year[0] === '0') {
                  this.YearOfCode = "Vous avez introduit un code activant un abonnement de Residanat"
                } else if (data.year[0] === '1') {
                  this.YearOfCode = "Vous avez introduit un code activant un abonnement de la " + data.year[0] + "ere annee"
                } else {
                  this.YearOfCode = "Vous avez introduit un code activant un abonnement de la " + data.year[0] + "eme annee"
                }
              } else if ((data.year[0] === null || typeof data.year[0] === 'undefined') && (data.module !== null || typeof data.module !== 'undefined')) {
                this.verifyCode()
              }
            } else if (data.result == 1) {
              this.verifyCode()
            } else if (data.result == null) {
              this.errorm()
            }

          }).catch(() => {

          });
        }
      }
    },
    previous() {
      this.active = this.active - 1
    },
    changeSubscribeList(action) {
      this.$store.commit("set_subscriptions", action);
    },
    verifyCode() {
      axiosIns.put("/verificationcode",
        {
          Activation_code: this.code,
        }
      ).then(({ data }) => {

        switch (data.result) {

          case 'Successful':
            if (data.mode === 'annuel') {
              if (data.year != '0') {
                this.subscriptionsList.push(data.year)
                if (data.year == '1') {
                  this.success("1ere année ");
                } else {
                  var s = data.year + "eme année "
                  this.success(s);
                }
              } else {
                this.subscriptionsList = ['1', '2', '3', '4', '5', '6']
                this.success("Résidanat ");
              }
              this.changeSubscribeList(this.subscriptionsList)
            }
            else if (data.mode === 'modulaire') {
              this.success(' ' + data.module.title + ' ');
            }
            this.active = this.active + 1;

            break;
          case "Code deja actif":
            this.errorm_code_actif()
            break;
          case "Code expiree":
            this.errorm_code_expire()
            break;
          case "Code invalide":
            this.errorm_code_invalide()
            break;
        }
        this.closeConfirmationModal()

      }).catch(() => {

      });
    },
  },

  mounted() {
    this.isLoading = true;
    if (typeof this.$route.query.year !== 'undefined') {
      axiosIns.get("/subscribeinfo/" + this.$route.query.year)
        .then(({ data }) => {
          this.yearInfo = data.result;
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    } else if (typeof this.$route.query.module !== 'undefined') {
      axiosIns.get("/subscribeinfomodule/" + this.$route.query.module)
        .then(({ data }) => {
          this.yearInfo = data.result;
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    }
  },

  computed: {
    isToken() {
      return this.$store.getters.get_token;
    },
    descriptionComponent: function () {

      return {
        template: '<div>' + this.yearInfo.description + '</div>',
      }
    },
    SubscribeNow() {
      return this.$store.getters.get_subscribeNow
    },
  },
  setup() {

    const success = (year) => {
      ElMessage({
        message: h('p', { style: 'color: #65a30d' }, [

          h('span', null, 'Votre abonnement de '),
          h('span', { style: 'font-weight: bold' }, year),
          h('span', null, 'a été activer avec succès'),
        ]),
        type: 'success',
      })
    }

    const errorm_code_actif = () => {
      ElMessage({
        message: h('p', { style: '' }, [

          h('span', null, 'Ce code est '),
          h('span', { style: 'font-weight: bold' }, 'deja activé '),
          h('span', null, 'veuillez réessayer avec un autre code !'),
        ]),
        type: 'error',
      })
    }

    const errorm_code_expire = () => {
      ElMessage({
        message: h('p', { style: '' }, [

          h('span', null, 'Ce code est '),
          h('span', { style: 'font-weight: bold' }, 'expiré '),
          h('span', null, 'veuillez réessayer avec un autre code !'),
        ]),
        type: 'error',
      })
    }

    const errorm = () => {
      ElMessage.error('Une erreur est survenue !')
    }

    const errorm_code_invalide = () => {
      ElMessage({
        message: h('p', { style: '' }, [

          h('span', null, 'Ce code est '),
          h('span', { style: 'font-weight: bold' }, 'invalide '),
          h('span', null, 'veuillez réessayer avec un autre code !'),
        ]),
        type: 'error',
      })
    }

    return { success, errorm_code_actif, errorm_code_expire, errorm_code_invalide, errorm }

  },
}

</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

.fulfilling-square-spinner,
.fulfilling-square-spinner * {
  box-sizing: border-box;
}

.fulfilling-square-spinner {
  height: 100px;
  width: 100px;
  position: relative;
  border: 4px solid #f87171;
  animation: fulfilling-square-spinner-animation 4s infinite ease;
}

.fulfilling-square-spinner .spinner-inner {
  vertical-align: top;
  display: inline-block;
  background-color: #f87171;
  width: 100%;
  opacity: 1;
  animation: fulfilling-square-spinner-inner-animation 4s infinite ease-in;
}

@keyframes fulfilling-square-spinner-animation {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(180deg);
  }

  50% {
    transform: rotate(180deg);
  }

  75% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes fulfilling-square-spinner-inner-animation {
  0% {
    height: 0%;
  }

  25% {
    height: 0%;
  }

  50% {
    height: 100%;
  }

  75% {
    height: 100%;
  }

  100% {
    height: 0%;
  }
}

.style-yasser {
  font-family: "Montserrat", Helvetica, Arial, serif;
}

/* table, th, td {
  border: 1px solid black;
} */

.custom-loading .circular {
  margin-right: 6px;
  width: 18px;
  height: 18px;
  animation: loading-rotate 2s linear infinite;
}

.custom-loading .circular .path {
  animation: loading-dash 1.5s ease-in-out infinite;
  stroke-dasharray: 90, 150;
  stroke-dashoffset: 0;
  stroke-width: 2;
  stroke: var(--el-button-text-color);
  stroke-linecap: round;
}
</style>